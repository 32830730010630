import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6f52844"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-container" }
const _hoisted_2 = { class: "form-item-box" }
const _hoisted_3 = { class: "form-item-box" }
const _hoisted_4 = { class: "form-item" }
const _hoisted_5 = { class: "form-item-box" }
const _hoisted_6 = { class: "form-item-box" }
const _hoisted_7 = { class: "form-item-box" }

import axios from "axios";
import { Uploader } from "vant";
import DialogBox from "@/components/steel/dialogBox.vue";
import { ref, reactive, watch, inject, getCurrentInstance } from "vue";
import {
    $apiTypesList,
    $apiGoodsInfo,
    $apiInventoryInfo,
} from "@/api/modules/steward.js";

export default {
  props: {
    goods_id: Number,
    dialog_show: Boolean,
    goods_type: Boolean,
},
  emits: ["closeEdit", "refreshList"],
  setup(__props, { emit: $emits }) {

const props = __props

const reload = inject("reload");

const { proxy: { $msg, $filters: { concatImg }, }, } = getCurrentInstance();


const state = reactive({
    fileList: [],
    typeList: [],
    submit_bool: true,
    typeIndex: "",
    sonTypeList: [],
    token: localStorage.getItem("token"),
    current_file_id: "",
    goodsId: "",
    goods_status: "",
    dialog_show: false,
});

const ruleFormRef = ref();
// // # 上架信息 √
const newlyInfo = reactive({
    name: "" /* 品名 */,
    texture: "" /* 材质 */,
    spec: "" /* 规格 */,
    price: "" /* 单价 */,
    unit: "元/吨" /* 单位 */,
    weight_ton: "" /* 件重 */,
    number: "" /* 库存 */,
    steel_mill: "" /* 钢厂名称 */,
    warehouse: "" /* 仓库名称 */,
    warehouse_address: "" /* 仓库地址 */,
    quality: "" /* 质量等级 */,
    craft: "" /* 生产工艺 */,
    note: "" /* 备注说明 */,
    region_id: 3 /* 所属市场 */,
    sonType: "" /* 钢材分类 */,
    type: 3 /* 商品分类 */,
    token: state.token /* token */,
    before_price: "" /* VIP 价格 */,
    images: [],
});
// // # 数字验证 √
const checkAge = (rule, value, callback) =>
    !Number(value) ? callback(new Error("请输入数字")) : callback();
// // # 输入判断 √
const rulesBool = (val) =>
    Object({ required: true, message: `请输入${val}`, trigger: "change" });
// // # 提交判空 √
const submitForm = (formEl) =>
    formEl.validate((valid) => (valid ? formDataInfo() : $msg.error("请填写完整")));
// // # 商品图片 √
const getUploadImages = (e) => {
    if (e.file) {
        newlyInfo.images = [];
        newlyInfo.images.push(e.file);
    }
};
// // # 数据处理
const formDataInfo = () => {
    if (!state.submit_bool) return;
    let formData = new FormData();
    for (let key in newlyInfo) {
        key !== "images" && formData.append(key, newlyInfo[key]);
    }
    if (newlyInfo.images.length) {
        for (const item of newlyInfo.images) {
            if (item.name) {
                formData.append("images[]", item);
            }
        }
    }

    state.submit_bool = false;
    if (state.goodsId) {
        formData.append("id", state.goodsId);
        editGoodsList(formData);
    } else {
        addGoodsList(formData);
    }
};

const refDialog = ref(null);

//# 进行商品添加
async function addGoodsList(params) {
    const dataInfo = await axios.post(
        "https://gangxinbao.cn/api/steward/inventory/add",
        params
    );
    const { status, data, msg } = dataInfo.data;
    status ? $msg.success(data) && reload() : $msg.error(msg);
    state.submit_bool = true;
}
//# 进行商品编辑
async function editGoodsList(params) {
    const api = state.goods_status
        ? "https://gangxinbao.cn/api/steward/goods/edit"
        : "https://gangxinbao.cn/api/steward/inventory";
    const dataInfo = await axios.post(api, params);
    const { status, data, msg } = dataInfo.data;
    state.submit_bool = true;
    if (status) {
        $msg.success(data);
        refDialog.value.changeDialog();
        $emits("refreshList");
    } else {
        $msg.error(msg);
    }
}

// # 获取钢材  √
getTypeList();
async function getTypeList() {
    const { status, data } = await $apiTypesList();
    if (status) {
        state.typeList = data;
        state.typeIndex = 0;
    }
}
const activeTypeTab = (i) => {
    state.sonTypeList.splice(0, Infinity);
    state.sonTypeList.push(...state.typeList[i].type);
    if (!newlyInfo.sonType) {
        newlyInfo.sonType = state.sonTypeList[0].id;
    }
};
watch(
    () => state.typeIndex,
    (val) => {
        activeTypeTab(val);
    },
    { deep: true }
);
watch(
    () => props,
    (val) => {
        if (val.goods_id && val.dialog_show) {
            getGoodsInfo(val);
        }
    },
    { deep: true }
);

// @ 获取商品信息
async function getGoodsInfo(goods) {
    state.fileList= []
    state.goodsId = goods.goods_id;
    state.goods_status = goods.goods_type;
    const $currentApi = goods.goods_type ? $apiGoodsInfo : $apiInventoryInfo;
    const data = await $currentApi({ token: state.token, id: goods.goods_id });
    if (data.status) {
        let info = data.data;
        if (data.data.images && data.data.images[0] && data.data.images[0].length) {
            state.fileList.splice(0, Infinity);
            state.fileList.push({ url: concatImg(data.data.images[0]) });
        }
        for (let t = 0; t < state.typeList.length; t++) {
            if (state.typeList[t].id == info.type_id) {
                state.typeIndex = t;
                break;
            }
        }
        for (const key in newlyInfo) {
            if (key == "token" || key == "sonType") {
                continue;
            } else {
                newlyInfo[key] = info[key];
            }
        }
        newlyInfo.sonType = info.type_t_id;
    }
}

return (_ctx, _cache) => {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(DialogBox, {
    title: "编辑商品",
    dialogShow: __props.dialog_show,
    onClose: _cache[16] || (_cache[16] = $event => ($emits('closeEdit'))),
    onConfirm: formDataInfo,
    ref_key: "refDialog",
    ref: refDialog
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          ref_key: "ruleFormRef",
          ref: ruleFormRef,
          model: _unref(newlyInfo),
          rules: _ctx.rules,
          "label-position": "right",
          class: "form_body"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "分类：",
              rules: { required: true },
              class: "type_box"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _unref(state).typeIndex,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_unref(state).typeIndex) = $event)),
                  placeholder: "请选择分类"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).typeList, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        label: item.type_title,
                        value: index,
                        key: index
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_select, {
                  modelValue: _unref(newlyInfo).sonType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_unref(newlyInfo).sonType) = $event)),
                  placeholder: "请选择分类"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).sonTypeList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        label: item.type_title,
                        value: item.id,
                        key: item.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_form_item, {
                label: "品名：",
                prop: "name",
                rules: rulesBool('品名'),
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_unref(newlyInfo).name) = $event)),
                    placeholder: "请输入品名  例：热轧板卷",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_component_el_form_item, {
                label: "材质：",
                prop: "texture",
                rules: rulesBool('材质'),
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).texture,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_unref(newlyInfo).texture) = $event)),
                    placeholder: "请输入材质  例：SPHC",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_component_el_form_item, {
                label: "规格：",
                prop: "spec",
                rules: rulesBool('规格'),
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).spec,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_unref(newlyInfo).spec) = $event)),
                    placeholder: "请输入规格  例：5.52*1660*C",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_form_item, {
                  label: "单价：",
                  prop: "price",
                  rules: { required: true, validator: checkAge }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(newlyInfo).price,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_unref(newlyInfo).price) = $event)),
                      placeholder: "请输入商品单价",
                      class: "info_input_val"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createVNode(_component_el_select, {
                  modelValue: _unref(newlyInfo).unit,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_unref(newlyInfo).unit) = $event)),
                  placeholder: "请选择单位",
                  class: "select-box"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "元/吨",
                      value: "元/吨"
                    }),
                    _createVNode(_component_el_option, {
                      label: "元/支",
                      value: "元/支"
                    }),
                    _createVNode(_component_el_option, {
                      label: "元/件",
                      value: "元/件"
                    }),
                    _createVNode(_component_el_option, {
                      label: "元/千克",
                      value: "元/千克"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_el_form_item, {
                label: "件重：",
                prop: "weight_ton",
                rules: rulesBool('件重'),
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).weight_ton,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_unref(newlyInfo).weight_ton) = $event)),
                    placeholder: "请输入件重",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_component_el_form_item, {
                label: "库存：",
                prop: "number",
                rules: rulesBool('库存'),
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).number,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_unref(newlyInfo).number) = $event)),
                    placeholder: "请输入库存",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_form_item, {
                label: "VIP价格：",
                prop: "before_price",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).before_price,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_unref(newlyInfo).before_price) = $event)),
                    placeholder: "请输入VIP价格",
                    clearable: "",
                    class: "info_input_val",
                    disabled: _unref(newlyInfo).type !== 3
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "钢厂名称：",
                prop: "steel_mill",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).steel_mill,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_unref(newlyInfo).steel_mill) = $event)),
                    placeholder: "请输入钢厂名称",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "仓库名称：",
                prop: "warehouse",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).warehouse,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_unref(newlyInfo).warehouse) = $event)),
                    placeholder: "请输入仓库",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_form_item, {
                label: "仓库地址：",
                prop: "warehouse_address",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).warehouse_address,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_unref(newlyInfo).warehouse_address) = $event)),
                    placeholder: "请输入仓库地址",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "质量等级：",
                prop: "quality",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).quality,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_unref(newlyInfo).quality) = $event)),
                    placeholder: "请输入质量等级",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "生产工艺：",
                prop: "craft",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(newlyInfo).craft,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_unref(newlyInfo).craft) = $event)),
                    placeholder: "请输入生产工艺",
                    clearable: "",
                    class: "info_input_val"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_form_item, {
                label: "商品图片：",
                class: "form-item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Uploader), {
                    modelValue: _unref(state).fileList,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_unref(state).fileList) = $event)),
                    multiple: "",
                    "max-count": 1,
                    "after-read": getUploadImages,
                    "preview-full-image": false
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _: 1
  }, 8, ["dialogShow"]))
}
}

}