import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d0caa4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "DialogBox" }
const _hoisted_2 = { class: "dialog-footer" }

import { ref, watch } from "vue";

export default {
  props: {title:String,dialogShow:Boolean,btn_text:{ type:Object, default:{ cancel:"取消", confirm:"确认" } }},
  emits: ['close',"confirm"],
  setup(__props, { expose, emit: emits }) {

const props = __props



let dialog_show = ref(false)
watch(props,(val)=>{
  if (val.dialogShow) {
    dialog_show.value =val.dialogShow
  }
},{deep:true})

const changeDialog=()=> {
  dialog_show.value =false
}
expose({
  changeDialog
})

return (_ctx, _cache) => {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _unref(dialog_show),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (_isRef(dialog_show) ? (dialog_show).value = $event : dialog_show = $event)),
      title: __props.title,
      width: "auto",
      draggable: "",
      "show-close": emits('close')
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = $event => (_isRef(dialog_show) ? dialog_show.value = false : dialog_show = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.btn_text.cancel), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[1] || (_cache[1] = $event => (emits('confirm'))),
            class: "btn-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.btn_text.confirm), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["modelValue", "title", "show-close"])
  ]))
}
}

}